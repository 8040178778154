import React, { FC, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SvgHelperWrapper } from '../Icon/iconHelper';
import * as S from './styles';
import Facebook from './footer-logo-facebook.svg';
import Twitter from './footer-logo-twitter.svg';
import Instagram from './footer-logo-instagram.svg';
import YouTube from './footer-logo-youtube.svg';
import SwooshComponent from './footer-swoosh.svg';

const msgs = defineMessages({
  aboutUs: {
    id: 'footer_link_about_us',
    defaultMessage: 'About Us',
  },
  team: {
    id: 'footer_link_team',
    defaultMessage: 'Team',
  },
  jobs: {
    id: 'footer_link_jobs',
    defaultMessage: 'Jobs',
  },
  memriseBlog: {
    id: 'footer_link_memrise_blog',
    defaultMessage: 'Memrise blog',
  },
  engineeringBlog: {
    id: 'footer_link_engineering_blog',
    defaultMessage: 'Engineering blog',
  },
  press: {
    id: 'footer_link_press',
    defaultMessage: 'Press',
  },
  contactUs: {
    id: 'footer_link_contact_us',
    defaultMessage: 'Contact Us',
  },
  faq: {
    id: 'footer_link_faq_help',
    defaultMessage: 'FAQ & Help',
  },
  termsOfUse: {
    id: 'footer_link_terms',
    defaultMessage: 'Terms of Use',
  },
  privacyPolicy: {
    id: 'footer_link_privacy',
    defaultMessage: 'Privacy Policy',
  },
  cookiePolicy: {
    id: 'footer_link_cookies',
    defaultMessage: 'Cookie Policy',
  },
  seoTitleFacebook: {
    id: 'link_title_facebook',
    defaultMessage: 'Facebook link',
  },
  seoTitleTwitter: {
    id: 'link_title_twitter',
    defaultMessage: 'Twitter link',
  },
  seoTitleYouTube: {
    id: 'link_title_youtube',
    defaultMessage: 'YouTube link',
  },
  seoTitleInstagram: {
    id: 'link_title_instagram',
    defaultMessage: 'Instagram link',
  },
});

interface LinkProps {
  href: string;
  children?: ReactNode;
}

const FooterLink: FC<LinkProps> = ({ href, children }) => (
  <S.LinkContainer>
    <S.ContainedLink href={href}>{children}</S.ContainedLink>
  </S.LinkContainer>
);

const TermsLink = ({ href, children }: LinkProps) => (
  <S.TermsLinkContainer>
    <S.ContainedLink href={href}>{children}</S.ContainedLink>
  </S.TermsLinkContainer>
);

const socialIcons = {
  Facebook,
  Twitter,
  Instagram,
  YouTube,
};

interface SocialIconProps {
  iconName: keyof typeof socialIcons;
  href: string;
  title: string;
}

const SocialLink = ({ iconName, href, title }: SocialIconProps) => {
  const SocialIcon = socialIcons[iconName];
  return (
    <S.SocialIconContainer href={href} target="_blank" rel="noopener noreferrer" title={title}>
      <SvgHelperWrapper Svg={SocialIcon} />
    </S.SocialIconContainer>
  );
};

const Footer: FC = props => {
  const intl = useIntl();
  return (
    <S.MarginAdjuster {...props}>
      <S.Swoosh Svg={SwooshComponent} />
      <S.FooterRoot data-testid="footer">
        <S.FooterContainer>
          <S.AllLinks>
            <S.Row>
              <S.LinkColumn>
                <FooterLink href="https://www.memrise.com/about">
                  {intl.formatMessage(msgs.aboutUs)}
                </FooterLink>
                <FooterLink href="https://www.memrise.com/team">
                  {intl.formatMessage(msgs.team)}
                </FooterLink>
                <FooterLink href="https://www.memrise.com/jobs">
                  {intl.formatMessage(msgs.jobs)}
                </FooterLink>
              </S.LinkColumn>

              <S.LinkColumn>
                <FooterLink href="https://www.memrise.com/blog">
                  {intl.formatMessage(msgs.memriseBlog)}
                </FooterLink>
                <FooterLink href="https://engineering.memrise.com/">
                  {intl.formatMessage(msgs.engineeringBlog)}
                </FooterLink>
                <FooterLink href="https://www.memrise.com/press">
                  {intl.formatMessage(msgs.press)}
                </FooterLink>
              </S.LinkColumn>

              <S.LinkColumn>
                <FooterLink href="https://www.memrise.com/contact">
                  {intl.formatMessage(msgs.contactUs)}
                </FooterLink>
                <FooterLink href="https://memrise.zendesk.com/hc/">
                  {intl.formatMessage(msgs.faq)}
                </FooterLink>

                {intl.locale === 'en' && (
                  <FooterLink href="https://www.memrise.com/dictionary">Phrasebooks</FooterLink>
                )}
              </S.LinkColumn>
            </S.Row>

            <S.Row>
              <TermsLink href="https://www.memrise.com/terms">
                {intl.formatMessage(msgs.termsOfUse)}
              </TermsLink>
              <TermsLink href="https://www.memrise.com/privacy">
                {intl.formatMessage(msgs.privacyPolicy)}
              </TermsLink>
              <TermsLink href="https://www.memrise.com/cookies">
                {intl.formatMessage(msgs.cookiePolicy)}
              </TermsLink>
            </S.Row>
          </S.AllLinks>

          <S.AllIcons>
            <S.Row>
              <S.MemriseIconContainer
                data-testid="memriseLogo"
                href="https://www.memrise.com/"
                title="Home link"
              >
                <S.MemriseIcon name="memrise" />
              </S.MemriseIconContainer>
            </S.Row>

            <S.Row>
              <S.SocialLinks>
                <SocialLink
                  iconName="Facebook"
                  href="https://www.facebook.com/memrise"
                  title={intl.formatMessage(msgs.seoTitleFacebook)}
                />
                <SocialLink
                  iconName="Twitter"
                  href="https://www.twitter.com/memrise"
                  title={intl.formatMessage(msgs.seoTitleTwitter)}
                />
                <SocialLink
                  iconName="YouTube"
                  href="https://www.youtube.com/user/memrise"
                  title={intl.formatMessage(msgs.seoTitleYouTube)}
                />
                <SocialLink
                  iconName="Instagram"
                  href="https://www.instagram.com/memrise"
                  title={intl.formatMessage(msgs.seoTitleInstagram)}
                />
              </S.SocialLinks>
            </S.Row>
          </S.AllIcons>
        </S.FooterContainer>
      </S.FooterRoot>
    </S.MarginAdjuster>
  );
};

export default Footer;
