import { toCamelCaseDeep } from 'src/utils/transform';
import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class MissionsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getRecommendedMissions(
    languagePairId: number,
    params: Schema.RecommendedMissionsRequest,
  ): Promise<Types.RecommendedMissionsResponse> {
    const response = await this.get<
      Schema.RecommendedMissionsResponse,
      Schema.RecommendedMissionsRequest
    >(`/language_pairs/${languagePairId}/missions/`, params);
    return toCamelCaseDeep(response);
  }

  public async postUserMissions(
    languagePairId: number,
    locationSlug: string,
    params: Schema.UserMissionRequest,
  ): Promise<SuccessResponse> {
    return this.post(`/language_pairs/${languagePairId}/missions/${locationSlug}/`, {
      bodyObject: params,
    });
  }
}

export const MissionsApi = new MissionsApiClass();
