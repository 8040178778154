import styled, { css } from 'styled-components';
import { rtlFlip } from 'src/utils/theme';
import Icon from '../Icon';

export const Header = styled.div<{ $inverseColors?: boolean }>(
  ({ theme, $inverseColors }) => css`
    width: 100%;
    height: ${theme.sz.s16};
    background-color: ${$inverseColors ? theme.co.secondary20 : theme.co.neutral99};
    padding: ${theme.sz.s2} ${theme.sz.s6};
    display: flex;
    justify-content: flex-start;

    @media (max-width: ${theme.sz.md}) {
      height: ${theme.sz.s8};
      padding: ${theme.sz.s2} ${theme.sz.s4};
    }
  `,
);

export const Text = styled.div(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.small};
    font-weight: ${theme.DEPRECATED_ty.bold};
  `,
);

export const ArrowIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: ${theme.sz.s2};
    line-height: ${theme.DEPRECATED_ty.small};
    transform: scaleX(${rtlFlip(theme)});
  `,
);

export const LogoIcon = styled(Icon)(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.largest};
    margin-top: -${theme.sz.s2};
    color: ${theme.co.primary20};
  `,
);

export const Redirect = styled.a(
  ({ theme }) => css`
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: inherit;
    text-decoration: none;

    height: 100%;
    color: ${theme.co.secondary0};
    display: flex;
    align-items: center;
    padding: 0 ${theme.sz.s2};

    &:first-of-type {
      margin-right: ${theme.sz.s6};

      @media (max-width: ${theme.sz.md}) {
        margin: 0;
        display: none;
      }
    }
  `,
);

export const Content = styled.div<{ $inverseColors?: boolean }>(
  ({ theme, $inverseColors }) => css`
    display: flex;
    align-items: center;
    color: ${$inverseColors && theme.co.neutral99};
    text-wrap: nowrap;
  `,
);

export const BackButton = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `,
);

export const BetaWrapper = styled.div(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
  `,
);

export const Beta = styled.a(
  ({ theme }) => css`
    ${theme.typography.heading.mobile.xxs};
    text-decoration: none;
    margin: 0 ${theme.sz.s8} 0;
    white-space: nowrap;
    color: ${theme.co.neutral0};

    &:any-link:hover,
    &:any-link:focus-visible {
      color: ${theme.co.primary60};
    }
  `,
);
