import { LearnableProgress, LearningEvent } from 'src/api/learningSessions/types';
import * as Schema from './schema';
import { UserSyncProgress } from '.';

export const toApiLearningEvent = ({
  boxTemplate,
  bonusPoints,
  courseId,
  scenarioId,
  timeSpent,
  givenAnswer,
  learningElement,
  definitionElement,
  testId,
  memId,
  createdDate,
  currentStreak,
  growthLevel,
  learnableId,
  nextDate,
  lastDate,
  notDifficult,
  totalStreak,
  ...rest
}: LearningEvent): Schema.LearningEvent => ({
  box_template: boxTemplate,
  bonus_points: bonusPoints,
  course_id: courseId,
  scenario_id: scenarioId,
  time_spent: timeSpent,
  given_answer: givenAnswer,
  learning_element: learningElement,
  definition_element: definitionElement,
  test_id: testId,
  mem_id: memId,
  created_date: createdDate,
  current_streak: currentStreak,
  growth_level: growthLevel,
  learnable_id: learnableId,
  next_date: nextDate,
  last_date: lastDate,
  not_difficult: notDifficult,
  total_streak: totalStreak,
  ...rest,
});

export const fromApiUserSyncProgress = ({
  user_id,
  last_sync_token,
  thingusers,
}: Schema.UserSyncProgress): UserSyncProgress => {
  // Add error for hard to replicate Sentry issue WEB_CLIENT-61R
  /* istanbul ignore next: internal error */
  if (!thingusers) {
    throw new Error(
      `thingsusers is undefined: { user_id: ${user_id}, last_sync_token: ${last_sync_token}, thingusers: ${thingusers}}`,
    );
  }

  return {
    userId: user_id,
    lastSyncToken: last_sync_token,
    thingUsers: thingusers.map(fromApiLearnableProgress),
  };
};

export const fromApiLearnableProgress = ({
  learnable_id,
  total_streak,
  current_streak,
  growth_level,
  created_date,
  next_date,
  last_date,
  user_id,
  mem_id,
  is_difficult,
  not_difficult,
  ...rest
}: Schema.LearnableProgress): LearnableProgress => ({
  learnableId: learnable_id,
  totalStreak: total_streak,
  currentStreak: current_streak,
  growthLevel: growth_level,
  createdDate: created_date,
  nextDate: next_date,
  lastDate: last_date,
  userId: user_id,
  memId: mem_id,
  isDifficult: is_difficult,
  notDifficult: not_difficult,
  ...rest,
});
