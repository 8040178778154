import { Appboy } from 'src/types/braze';
import { generateTracker } from '../generateTracker';

/**
 * To be fired when signup form is submitted
 */
export const trackAccountCreationCompleted =
  (appboy?: Appboy) =>
  (
    authenticationId: string | null,
    marketingOptInChecked: boolean | null,
    timezone: string | null,
  ) =>
    generateTracker(appboy)('AccountCreationCompleted', () => ({
      authentication_id: authenticationId,
      marketing_opt_in_checked: marketingOptInChecked,
      timezone,
    }))();
