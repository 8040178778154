import React, { ComponentType, forwardRef, HTMLProps, ReactNode, Ref } from 'react';
import { IconName } from '../index';
import Icons from '../Icon/icons';
import * as S from './styles';

type IconSet = { [key: string]: ComponentType };

export const ButtonTypes = {
  PRIMARY: 'primary',
  PRIMARY_ALT: 'primary-alt',
  PRIMARY_PRO: 'primary-pro',
  SECONDARY: 'secondary',
  SECONDARY_DARK: 'secondary-dark',
  TERTIARY: 'tertiary',
  TERTIARY_ALT: 'tertiary-alt',
  LIGHT_BLUE: 'light-blue',
} as const;

export const ButtonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  EXTRA_SMALL: 'extraSmall',
} as const;

export type ButtonType = (typeof ButtonTypes)[keyof typeof ButtonTypes];

export type ButtonSize = (typeof ButtonSizes)[keyof typeof ButtonSizes];

export interface BaseButtonProps {
  icon?: IconName | ReactNode;
  disabled?: boolean;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  wide?: boolean;
  a11yText?: string;
  /** Prevents text from wrapping into multiple lines */
  noWrap?: boolean;
  className?: string;
  loading?: boolean;
  'data-testId'?: string;
}

function isIconName(value: string | ReactNode): value is IconName {
  const iconSet: IconSet = Icons;
  return typeof value === 'string' && iconSet[value] !== undefined;
}

type ButtonProps = BaseButtonProps &
  Pick<HTMLProps<HTMLButtonElement>, 'children' | 'onClick' | 'onKeyUp'>;

export const Button = forwardRef(
  (
    {
      children,
      icon,
      disabled,
      wide = false,
      buttonType = 'light-blue',
      buttonSize = 'small',
      onClick,
      onKeyUp,
      a11yText,
      noWrap,
      className,
      loading,
      ['data-testId']: dataTestId,
    }: ButtonProps,
    ref?: Ref<HTMLButtonElement>,
  ) => {
    return (
      <S.Button
        $buttonSize={buttonSize}
        $buttonType={buttonType}
        $disabled={disabled}
        $wide={wide}
        ref={ref}
        onClick={loading || disabled ? undefined : onClick}
        onKeyUp={onKeyUp}
        aria-label={a11yText}
        title={a11yText}
        $noWrap={noWrap}
        className={className}
        data-testid={dataTestId ?? 'scenarios-button'}
      >
        {loading && <S.ButtonLoader />}
        {isIconName(icon) ? (
          <S.Icon $disabled={disabled} name={icon} $buttonType={buttonType} useCurrentColor />
        ) : (
          icon
        )}
        {children && (
          <S.Content $isLeft={!!icon} $loading={loading}>
            {children}
          </S.Content>
        )}
      </S.Button>
    );
  },
);

Button.displayName = 'Button';
export default isIconName;
