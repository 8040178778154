import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Notification } from './types';

export const NotificationContext = React.createContext<{
  notification?: Notification;
  setNotification: (notification: Notification, duration?: number) => void;
}>({
  notification: undefined,
  setNotification: () => {},
});

const NotificationProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [notification, setNotification] = useState<Notification | undefined>(undefined);

  const setNotificationCallback = useCallback(
    (notification: Notification, duration?: number) => {
      setNotification({
        ...notification,
        onClick: () => {
          notification.onClick && notification.onClick();
          setNotification(undefined);
        },
      });
      setTimeout(() => {
        setNotification(undefined);
      }, duration || 5000);
    },
    [setNotification],
  );

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification: setNotificationCallback,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider };

export default NotificationContext;
