import { toCamelCaseDeep } from 'src/utils/transform';
import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class ExperienceApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getExperience(): Promise<Types.ExperienceResponse> {
    const response = await this.get<Schema.ExperienceResponse>(`/experience/`);
    return toCamelCaseDeep(response);
  }

  public async putExperience(params: Schema.ExperienceRequest): Promise<SuccessResponse> {
    return this.put(`/experience/`, {
      bodyObject: params,
    });
  }
}

export const ExperienceApi = new ExperienceApiClass();
