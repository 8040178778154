import { IntlShape } from 'react-intl';
import { getLocalStorageEntry } from '../webStorageUtils';
import { SOURCE_LANGUAGE, TARGET_LANGUAGE } from '../language/saveLastLanguagePair';
import { intlGetMessagesLocale } from '../intl';

export interface LanguagesInformation {
  content_language?: string;
  source_language?: string;
  target_language?: string;
}

export const getAnalyticsLanguageInfo = (intl: IntlShape): LanguagesInformation => {
  const languagesInfo: LanguagesInformation = {
    content_language: intlGetMessagesLocale(intl.locale),
  };

  const source = getLocalStorageEntry(SOURCE_LANGUAGE);
  if (source) {
    languagesInfo.source_language = source;
  }

  const target = getLocalStorageEntry(TARGET_LANGUAGE);
  if (target) {
    languagesInfo.target_language = target;
  }

  return languagesInfo;
};
