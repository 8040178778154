import { createGlobalStyle, css, DefaultTheme } from 'styled-components';
import Boing from './Boing.woff';
import Boing2 from './Boing.woff2';
import SFCompactDisplay from './SF-Compact-Display.ttf';

/**
 * Global styles to be injected across all pages of the app
 */
const GlobalStyle = createGlobalStyle(
  ({ theme }: { theme: DefaultTheme }) => css`
    * {
      box-sizing: border-box;

      /*
    This max-width value is to ensure that good typography is enforced
    across the app.
    Measure (the width of a block of text) should not exceed approx. 70
    characters so we apply this restriction to all elements and
    override non-text elements on a case-by-case below. */
      max-width: 70ex;
    }

    /* Override measure restrictions */
    body,
    button,
    canvas,
    div,
    footer,
    header,
    html,
    input,
    img,
    label,
    main,
    nav,
    picture,
    svg,
    source,
    table,
    video {
      max-width: none;
    }

    input {
      font: inherit;
    }

    button {
      font-family: inherit;
      color: ${theme.co.secondary0};
    }

    ins[id^='gpt_unit'] {
      min-width: 100%; // make ad render on page center rather than left

      iframe:first-child {
        min-width: 60%; // prevent larger-filling ads from being cut off

        @media (max-width: ${theme.sz.xxl}) {
          min-width: 100%; // large tablets and below should fill entire screen
        }
      }

      iframe:only-child {
        min-width: 100%;
      }
    }

    body {
      font-family: ${theme.DEPRECATED_ty.openSans}, sans-serif;
      margin: 0;

      *:focus {
        outline: none;
      }
      *:focus-visible {
        box-shadow: 0 0 0 3px ${theme.co.information50};
        outline: none;
      }
      &.ReactModal__Body--open {
        overflow: hidden;
      }
    }

    /* Please also note that Open Sans is imported in _document.tsx <Head /> */

    @font-face {
      font-family: 'Boing';
      src:
        url('${Boing2}') format('woff2'),
        url('${Boing}') format('woff');
      font-display: swap;
    }

    @font-face {
      font-family: 'SF Compact Display';
      src: url(${SFCompactDisplay}) format('truetype');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
  `,
);

export default GlobalStyle;
