import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Props as ReactModalProps } from 'react-modal';
import { defineMessages, useIntl } from 'react-intl';
import * as S from './styles';
import { ErrorModal } from './ErrorModal';

const msgs = defineMessages({
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
});

const CloseButtonWrapper: FC<{
  children: JSX.Element;
  stickyCloseButton: boolean;
}> = ({ children, stickyCloseButton }) =>
  stickyCloseButton ? <S.CloseWrapper>{children}</S.CloseWrapper> : children;

export interface ModalProps extends ReactModalProps {
  handleClose?: (_event: React.MouseEvent) => void;
  eosModal?: boolean;
  backgroundColor?: 'white' | 'primary99';
  closeIconType?: 'cross' | 'crossNew';
  darkCloseIcon?: boolean;
  hideCloseButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  children?: ReactNode;
  stickyCloseButton?: boolean;
  fullSize?: boolean;
}

const Modal: FC<ModalProps> = ({
  isOpen: propsIsOpen,
  handleClose,
  eosModal = false,
  children,
  closeIconType = 'crossNew',
  darkCloseIcon = false,
  hideCloseButton = false,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  backgroundColor = 'white',
  stickyCloseButton = false,
  fullSize = false,
  ...reactModalProps
}) => {
  const intl = useIntl();
  const closeText = intl.formatMessage(msgs.close);
  const [isOpen, setIsOpen] = useState(false);

  /* istanbul ignore next: tested manually */
  useEffect(() => {
    // In React strict mode, opens the modal asynchronously to prevent issues with conditional rendering
    // and with modals that are initially open. See this thread: https://github.com/reactjs/react-modal/issues/808
    setIsOpen(propsIsOpen);
  }, [propsIsOpen]);

  return (
    <S.Modal
      {...reactModalProps}
      isOpen={isOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={handleClose}
      $backgroundColor={backgroundColor}
      $fullSize={fullSize}
      $eosModal={eosModal}
    >
      {!hideCloseButton && (
        <CloseButtonWrapper stickyCloseButton={stickyCloseButton}>
          <S.CloseButton
            onClick={handleClose}
            title={closeText}
            aria-label={closeText}
            $closeIconType={closeIconType}
          >
            <S.CloseIcon $darkCloseIcon={darkCloseIcon} name={closeIconType} />
          </S.CloseButton>
        </CloseButtonWrapper>
      )}
      {children}
    </S.Modal>
  );
};

export { Modal, ErrorModal };
