import React, { FC, useCallback, useEffect, useReducer } from 'react';
import { MeApi } from 'src/api';
import { getAnalyticsLanguageInfo } from 'src/utils/analytics/getAnalyticsLanguageInfo';
import { getAnalyticsUserInfo } from 'src/utils/analytics/getAnalyticsUserInfo';
import { IntlShape } from 'react-intl';
import { fetchUserFailure, fetchUserSuccess, reset } from './actions';
import { UserContext } from './context';
import { initialState, reducer } from './reducer';
import { CompactUser, UserProviderProps } from './types';

type MeResponse = Awaited<ReturnType<typeof MeApi.me>>;

const ONE_HOUR = 3600000;

const meResponseToUser = ({ profile }: MeResponse): CompactUser => ({
  avatar: profile.avatar,
  email: profile.email,
  id: profile.id,
  isPro: profile.is_pro,
  isStaff: profile.is_staff,
  statistics: {
    points: profile.statistics.points,
    wordsLearnt: profile.statistics.num_things_flowered,
  },
  username: profile.username,
  dateJoined: profile.date_joined,
  language: profile.language,
  timezone: profile.timezone,
});

export const UserProvider: FC<UserProviderProps> = ({ children, testState, locale }) => {
  const [state, dispatch] = useReducer(reducer, testState || initialState);

  const fetchUser = useCallback(async () => {
    try {
      const meResponse = await MeApi.me();
      const user = meResponseToUser(meResponse);
      dispatch(fetchUserSuccess(user));
      window.dataLayer.push({
        event: 'login',
        page_screen_type: 'Login',
        page_screen_name: 'Login',
        ...getAnalyticsLanguageInfo({ locale } as IntlShape),
        ...getAnalyticsUserInfo(user),
      });
    } catch (error) {
      dispatch(fetchUserFailure(error as Error));
    }
    // should work only one time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUser = useCallback(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (!state.error && !state.user) void fetchUser();
  }, [fetchUser, state.error, state.user]);

  useEffect(() => {
    if (!state.user) return;
    const timeoutId = setTimeout(fetchUser, ONE_HOUR);
    return () => clearTimeout(timeoutId);
  }, [fetchUser, state.user]);

  return (
    <UserContext.Provider value={{ ...state, refetchUser, softRefetchUser: fetchUser }}>
      {children}
    </UserContext.Provider>
  );
};
