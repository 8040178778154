import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Schema from './schema';
import * as Types from './types';

export default class UserDictionaryClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getAllUserLearnables(
    languagePairId: number,
    params: Schema.UserLearnablesRequest,
  ): Promise<Types.UserLearnablesResponse> {
    const response = await this.get<Schema.UserLearnablesResponse, Schema.UserLearnablesRequest>(
      `/language_pairs/${languagePairId}/learnables/`,
      params,
    );
    return toCamelCaseDeep(response);
  }

  public async updateSingleLearnable(
    languagePairId: number,
    learnableIdKey: number,
    learnables: Schema.SingleLearnableStat,
  ) {
    await this.put(`/language_pairs/${languagePairId}/learnables/${learnableIdKey}/`, {
      bodyObject: learnables,
    });
  }

  public async updateLearnables(languagePairId: number, learnables: Schema.LearnablesStat) {
    await this.put(`/language_pairs/${languagePairId}/learnables/`, {
      bodyObject: learnables,
    });
  }
}

export const UserDictionaryApi = new UserDictionaryClass();
