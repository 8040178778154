import { createContext, useContext } from 'react';
import { initialState } from './reducer';
import { UserContextValue } from './types';

export const UserContext = createContext<UserContextValue>({
  ...initialState,
  refetchUser: () => {},
  softRefetchUser: () => {},
});

export const useUserContext = () => useContext(UserContext);
