import { toCamelCaseDeep } from 'src/utils/transform';
import * as Schema from './schema';
import * as Types from './types';

type ConvertGetScenarioResponse = (
  getScenarioResponse: Schema.ScenariosItem,
) => Types.ScenariosItem;

export const convertGetScenarioResponse: ConvertGetScenarioResponse = getScenarioResponse =>
  toCamelCaseDeep(getScenarioResponse);
