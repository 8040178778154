import MemriseApi from '../MemriseApi';
import { ExperimentFeaturesBody, ExperimentFeaturesResponse } from './schema';

export default class FeaturesApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/features' });
  }

  /**
   * Returns whether certain features or experiments are
   * enabled for the user or an anonymous device. These are
   * configured by administrators in Meme.
   *
   * The body should be encoded as a form (URL or multipart)
   * with one parameter data.
   *
   * https://docs.memrise.team/reference/rest-api-endpoints/features.html
   */
  public async featuresPost(params: ExperimentFeaturesBody): Promise<ExperimentFeaturesResponse> {
    // NB: This post endpoint is a little strange due to
    // double encoding
    // It takes a pre-encoding string as one of the
    // parameters on the body, so we have two levels
    // of encoding (both here and in MemriseApi.post)
    const stringifiedParams = JSON.stringify(params);
    return this.post('/', { bodyObject: { data: stringifiedParams } });
  }
}

export const FeaturesApi = new FeaturesApiClass();
