import { toCamelCaseDeep } from 'src/utils/transform';
import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class SkillLevelApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async postSkillLevelSelection(
    languagePairId: number,
    params: Schema.SkillLevelRequest,
  ): Promise<SuccessResponse> {
    return this.post(`/language_pairs/${languagePairId}/skill_level_selection/`, {
      bodyObject: params,
    });
  }

  public async getSkillLevelSelection(
    languagePairId: number,
  ): Promise<Types.SkillLevelSelectionResponse> {
    const response = await this.get<Schema.SkillLevelSelectionResponse>(
      `/language_pairs/${languagePairId}/skill_level_selection/`,
    );
    return toCamelCaseDeep(response);
  }
}

export const SkillLevelApi = new SkillLevelApiClass();
