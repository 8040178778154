import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class EligibilityApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getEligibility(languagePairId: number): Promise<Types.EligibilityResponse> {
    const response = await this.get<Schema.EligibilityResponse>(
      `/language_pairs/${languagePairId}/features/`,
    );
    return toCamelCaseDeep(response);
  }
}

export const EligibilityApi = new EligibilityApiClass();
