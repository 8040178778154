import { toCamelCaseDeep } from 'src/utils/transform';
import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class MediaApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getLanguagePairMedia(
    languagePairId: number,
    params?: Schema.LanguagePairMediaRequest,
  ): Promise<Types.LanguagePairMediaResponse> {
    const response = await this.get<
      Schema.LanguagePairMediaResponse,
      Schema.LanguagePairMediaRequest
    >(`/language_pairs/${languagePairId}/media/`, params);
    return toCamelCaseDeep(response);
  }

  public async postMedia(
    contentMediaId: number,
    languagePairId?: number,
    params?: Schema.ContentMediaRequest,
  ): Promise<SuccessResponse> {
    return this.post(`/language_pairs/${languagePairId}/media/${contentMediaId}/`, {
      bodyObject: params,
    });
  }

  public async getMedia(
    languagePairId: number,
    contentMediaId: string,
  ): Promise<Types.MediaResponse> {
    const response = await this.get<Schema.MediaResponse>(
      `/language_pairs/${languagePairId}/media/${contentMediaId}/`,
    );
    return toCamelCaseDeep(response);
  }
}

export const MediaApi = new MediaApiClass();
