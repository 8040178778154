export interface UserInformation {
  logged_in?: string;
  user_id?: string;
  user_tier?: string;
}
export interface User {
  id: number;
  isPro: boolean;
}

export const getAnalyticsUserInfo = (user: User | null): UserInformation => {
  if (user) {
    return {
      logged_in: 'true',
      user_id: `${user.id}`,
      user_tier: user.isPro ? 'pro' : 'free',
    };
  }
  return { logged_in: 'false' };
};
