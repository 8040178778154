import getConfig from 'next/config';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Appboy } from 'src/types/braze';
import { v4 as uuidv4 } from 'uuid';
import { getLocalStorageEntry, removeLocalStorageEntry } from 'src/utils/webStorageUtils';
import { getTimezone } from 'src/utils/dateTime';
import { trackAccountCreationCompleted } from 'src/analytics';
import { useUserContext } from '../user';

export const BrazeContext = createContext<{
  appboy?: Appboy;
  setAppboy: Dispatch<SetStateAction<Appboy | undefined>>;
}>({
  appboy: undefined,
  setAppboy: () => {},
});

export const BrazeProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { publicRuntimeConfig } = getConfig();
  const { user } = useUserContext();
  const [appboy, setAppboy] = useState<Appboy | undefined>(undefined);

  useEffect(() => {
    if (user?.id) {
      import('@braze/web-sdk')
        .then(appboySdk => {
          /* istanbul ignore else: empty else branch */
          if (typeof appboySdk !== 'undefined') {
            appboySdk.initialize(publicRuntimeConfig.BRAZE_API_KEY_WEB, {
              baseUrl: 'sdk.fra-01.braze.eu',
              enableLogging: false, // change to true for local testing
              allowUserSuppliedJavascript: true, // allow Braze dashboard users to run Javascript
            });
            appboySdk.automaticallyShowInAppMessages();
            appboySdk.changeUser(user.id.toString());

            appboySdk.openSession();
            setAppboy(appboySdk);
          }
        })
        .catch(e => {
          console.error(`Error importing Braze SDK: ${e}`);
        });
    }
    // We only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  // Hack for 'user_account_created' event
  // Braze initialization happens after user is authorized and has an id.
  // So before sending events we need to know that Braze is ready.
  // Braze will start initialization ONLY when the next page renders.
  // After creating account new user can be on any page (if there are 'next' param in the url)
  // So for this event it is better to send it after Braze initialization.
  useEffect(() => {
    if (!!appboy && getLocalStorageEntry('IS_NEW_BRAZE_USER') === 'true') {
      removeLocalStorageEntry('IS_NEW_BRAZE_USER');

      const timezone = getTimezone();
      const authenticationId = uuidv4();
      trackAccountCreationCompleted(appboy)(authenticationId, null, timezone);
    }
  }, [user?.id, appboy]);

  const contextValue = useMemo(() => ({ appboy, setAppboy }), [appboy, setAppboy]);

  return <BrazeContext.Provider value={contextValue}>{children}</BrazeContext.Provider>;
};

export const useBrazeContext = () => useContext(BrazeContext);
