import { Appboy } from 'src/types/braze';
import { generateTracker } from '../generateTracker';

export enum LearningSessionType {
  unknown_session_type = 'unknown_session_type',
  learn = 'learn',
  review = 'review',
  speed_review = 'speed_review',
  difficult_words = 'difficult_words',
  audio = 'audio',
  video = 'video',
  speaking = 'speaking',
  practice = 'practice',
  grammar_learn = 'grammar_learn',
  grammar_review = 'grammar_review',
  grammar_practice = 'grammar_practice',
  preview = 'preview',
  communicate_responses = 'communicate_responses',
}

export enum LanguageDirection {
  unknown_language_direction = 'unknown_language_direction',
  source = 'source',
  target = 'target',
}

export enum ResponseType {
  unknown_response_type = 'unknown_response_type',
  tapping = 'tapping',
  record_compare = 'record_compare',
  multiple_choice = 'multiple_choice',
  typing = 'typing',
  audio_multiple_choice = 'audio_multiple_choice',
  video_context = 'video_context',
  audio_segmentation = 'audio_segmentation',
  comprehension = 'comprehension',
  communicate_responses = 'communicate_responses',
}

/**
 * Fires when learning session begins
 */
export const trackLearningSessionStarted =
  (appboy?: Appboy) =>
  (
    learningSessionId: string | null,
    learningSessionType: LearningSessionType | null,
    numOfItemsForReview: number | null,
    sessionItems: number | null,
    scenarioId: number | null,
    languagePairId: number | null,
  ) =>
    generateTracker(appboy)('LearningSessionStarted', () => ({
      learning_session_id: learningSessionId,
      learning_session_type: learningSessionType != null ? learningSessionType.toString() : null,
      num_of_items_for_review: numOfItemsForReview,
      session_items: sessionItems,
      scenario_id: scenarioId,
      language_pair_id: languagePairId,
    }));

/**
 * Fires when learning session finishes
 * (Includes preview sessions)
 */
export const trackLearningSessionCompleted =
  (appboy?: Appboy) =>
  (
    learningSessionId: string | null,
    bonusPointsEarned: number | null,
    learningSessionType: LearningSessionType | null,
    sessionItems: number | null,
    scenarioId: number | null,
    languagePairId: number | null,
  ) =>
    generateTracker(appboy)('LearningSessionCompleted', () => ({
      learning_session_id: learningSessionId,
      bonus_points_earned: bonusPointsEarned,
      learning_session_type: learningSessionType != null ? learningSessionType.toString() : null,
      session_items: sessionItems,
      scenario_id: scenarioId,
      language_pair_id: languagePairId,
    }));

/**
 * Fires when the scenario is completed
 */
export const trackScenarioCompleted =
  (appboy?: Appboy) =>
  (learningSessionId: string | null, scenarioId: number | null, languagePairId: number | null) =>
    generateTracker(appboy)('ScenarioCompleted', () => ({
      learning_session_id: learningSessionId,
      scenario_id: scenarioId,
      language_pair_id: languagePairId,
    }));
