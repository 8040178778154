import { Appboy } from 'src/types/braze';
import { generateTracker } from '../generateTracker';

export const trackCommunicateTabOfficialClicked = (appboy?: Appboy) =>
  generateTracker(appboy)('CommunicateTabOfficialClicked');

export const trackHomeTabOfficialClicked = (appboy?: Appboy) =>
  generateTracker(appboy)('HomeTabOfficialClicked');

export const trackImmerseTabOfficialClicked = (appboy?: Appboy) =>
  generateTracker(appboy)('ImmerseTabOfficialClicked');

export const trackLearnTabOfficialClicked = (appboy?: Appboy) =>
  generateTracker(appboy)('LearnTabOfficialClicked');
