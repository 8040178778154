import { Appboy } from 'src/types/braze';
import { generateTracker } from '../generateTracker';

/**
 * Fires when a user completes their daily streak
 */
export const trackUserStreakAchieved = (appboy?: Appboy) => (count: number | null) =>
  generateTracker(appboy)('UserStreakAchieved', () => ({
    count,
  }));
