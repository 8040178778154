import { Appboy } from 'src/types/braze';
import { generateTracker } from '../generateTracker';

export enum FlowOrigin {
  onboarding = 'onboarding',
  new_language_selection = 'new_language_selection',
}

export enum SkillLevel {
  learn_from_scratch = 'learn_from_scratch',
  skip_learning_the_alphabet = 'skip_learning_the_alphabet',
  beginner = 'beginner',
  elementary = 'elementary',
  intermediate = 'intermediate',
}

export const trackSkillLevelSelected =
  (appboy?: Appboy) =>
  (skillLevel: SkillLevel | null, flowOrigin: FlowOrigin | null, languagePairId: number | null) =>
    generateTracker(appboy)('SkillLevelSelected', () => ({
      skill_level: skillLevel != null ? skillLevel.toString() : null,
      flow_origin: flowOrigin != null ? flowOrigin.toString() : null,
      language_pair_id: languagePairId,
    }));
