import { Appboy } from 'src/types/braze';
import { DifficultyRating } from 'src/api/media/types';
import { generateTracker } from '../generateTracker';

export enum Status {
  locked = 'locked',
  some_words_learned = 'some_words_learned',
  most_words_learned = 'most_words_learned',
}

export enum SubtitleLanguage {
  source = 'source',
  target = 'target',
  none = 'none',
}

export enum StartSource {
  from_tab = 'from_tab',
  from_next = 'from_next',
  replay = 'replay',
}

/**
 * Fires when the user starts watching a content media
 */
export const trackContentMediaStarted =
  (appboy?: Appboy) =>
  (
    contentMediaId: number | null,
    mediaSessionId: string | null,
    startSource: StartSource | null,
    videoStatus: Status | null,
    videoLength: number | null,
    difficultyRating: DifficultyRating | null,
    subtitleLanguage: SubtitleLanguage | null,
    sourceLanguage: string | null,
    targetLanguage: string | null,
  ) =>
    generateTracker(appboy)('ContentMediaStarted', () => ({
      content_media_id: contentMediaId,
      media_session_id: mediaSessionId,
      start_source: startSource != null ? startSource.toString() : null,
      video_status: videoStatus != null ? videoStatus.toString() : null,
      video_length: videoLength,
      difficulty_rating: difficultyRating != null ? difficultyRating.toString() : null,
      subtitle_language: subtitleLanguage != null ? subtitleLanguage.toString() : null,
      source_language: sourceLanguage,
      target_language: targetLanguage,
    }));

/**
 * Fires when the user finished watching a content media
 */
export const trackContentMediaCompleted =
  (appboy?: Appboy) => (contentMediaId: number | null, mediaSessionId: string | null) =>
    generateTracker(appboy)('ContentMediaCompleted', () => ({
      content_media_id: contentMediaId,
      media_session_id: mediaSessionId,
    }));
