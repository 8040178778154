import { setLocalStorageEntry } from 'src/utils/webStorageUtils';

export const LANGUAGE_PAIR_ID = 'LANGUAGE-PAIR-ID';
export const SOURCE_LANGUAGE = 'SOURCE_LANGUAGE';
export const TARGET_LANGUAGE = 'TARGET_LANGUAGE';

export const saveLastLanguagePair = (languagePairId: number, source?: string, target?: string) => {
  setLocalStorageEntry(LANGUAGE_PAIR_ID, languagePairId.toString());
  source && setLocalStorageEntry(SOURCE_LANGUAGE, source);
  target && setLocalStorageEntry(TARGET_LANGUAGE, target);
};
