import React, { ReactNode, FC } from 'react';
import { Modal, ModalProps } from '..';
import * as S from './styles';

export type Props = Omit<ModalProps, 'children'> & {
  errorTitle: string;
  errorDetail?: string;
  children?: ReactNode;
};

export const ErrorModal: FC<Props> = ({ errorTitle, errorDetail, children, ...passedProps }) => {
  return (
    <Modal
      hideCloseButton
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      {...passedProps}
    >
      <S.Error>
        <S.ErrorTitle>{errorTitle}</S.ErrorTitle>
        <S.ErrorMessage>{errorDetail}</S.ErrorMessage>
        {children}
      </S.Error>
    </Modal>
  );
};
