import fetchRetryGenerator from 'fetch-retry';
import getConfig from 'next/config';
import { objectToCookie } from 'src/cookies';
import { randomInteger } from 'src/utils/numbers';
import { isServerSide } from 'src/utils/env/isServerSide';
import { getCsrfToken } from './csrf';

// Fix for Storybook. Next server doesn't run during storybook running.
/* istanbul ignore next: untested conditions for undefined values */
const { publicRuntimeConfig = {} } = getConfig() || {};
export const API_VERSION = 'v1.24';
// Do NOT make isSSR optional below otherwise missing https:// + host will throw TypeError: Invalid URL which fails deploys: https://sentry.io/organizations/memrise/issues/3851366942/?environment=staging&project=5891339
export const makeBaseURL = (hostServerSide: string, isSSR = isServerSide()) => {
  if (isSSR) {
    // This is needed for the preview environment to work with SSR pages such as /membot
    if (hostServerSide.includes('webapp-staging-api')) {
      return `http://${hostServerSide}/${API_VERSION}`;
    } else {
      return `https://${hostServerSide}/${API_VERSION}`;
    }
  } else {
    return `/${API_VERSION}`;
  }
};

// This is needed in dev as our API does not have a valid HTTPS cert locally
/* istanbul ignore next: too hard to test */
if (publicRuntimeConfig.NODE_ENV === 'development') {
  // @ts-ignore: Environment variable not typed
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

const baseOptions = {
  credentials: 'same-origin' as const,
};

export const makeHeaders = async (
  {
    method,
    baseURL,
    cookies,
    ip,
    acceptLanguage,
    body,
  }: {
    baseURL: string;
    method?: string;
    cookies?: Record<string, unknown>;
    ip?: string;
    acceptLanguage?: string;
    body?: RequestInit['body'];
  },
  isSSR = isServerSide(),
) => ({
  ...ipHeaderObj(ip, isSSR),
  ...bodyHeaderObj(body),
  ...cookiesHeaderObj(cookies),
  ...(await csrfHeaderObj(baseURL, method, isSSR)),
  ...spoofXForwardedProto(baseURL),
  ...acceptLanguageHeaderObj(acceptLanguage),
});

export const ipHeaderObj = (
  ip: string | undefined,
  isSSR: boolean,
): { 'webclient-proxy-address': string } | Record<string, never> =>
  ip && isSSR ? { 'webclient-proxy-address': ip } : {};

export const acceptLanguageHeaderObj = (
  acceptLanguage: string | undefined,
): { 'Accept-Language': string } | Record<string, never> =>
  acceptLanguage ? { 'Accept-Language': acceptLanguage } : {};

const bodyHeaderObj = (
  body: RequestInit['body'] | undefined,
): { 'Content-Type': 'application/json' } | Record<string, never> =>
  body ? { 'Content-Type': 'application/json' } : {};

const cookiesHeaderObj = (
  cookies: Record<string, unknown> | undefined,
): { Cookie: string } | Record<string, never> =>
  cookies ? { Cookie: objectToCookie(cookies) } : {};

export const csrfHeaderObj = async (
  baseURL: string,
  method: string | undefined,
  isSSR: boolean,
): Promise<Record<string, never> | { 'X-CSRFToken': string }> => {
  // Browser POST requests need to send CSRF token - server or GET don't
  const csrfToken = !isSSR && method !== 'GET' ? await getCsrfToken(baseURL) : null;
  return csrfToken ? { 'X-CSRFToken': csrfToken } : {};
};

// Without this, webapp sends back a redirect to the request for the HTTPS
// version of the URL, which doesn't work because webapp doesn't listen on HTTPS
// itself. The request then instead needs to be sent via the load balancers, but
// that requires Okta authentication.
const spoofXForwardedProto = (
  baseURL: string,
): { 'X-Forwarded-Proto': 'https' } | Record<string, never> =>
  baseURL.startsWith('http://') ? { 'X-Forwarded-Proto': 'https' } : {};

/**
 * Function for low level parts of calling our own web API
 * - Not expecting valid certificates in development
 * - Adds CSRF headers as appropriate
 */
export const memriseApiFetch = async (
  endpoint: string,
  options: RequestInit & {
    cookies?: Record<string, unknown>;
    ip?: string;
    acceptLanguage?: string;
  },
): Promise<Response> => {
  const hostServerSide = publicRuntimeConfig.MEMRISE_API_HOST;
  const baseURL = makeBaseURL(hostServerSide);
  const url = baseURL + endpoint;

  const { method, cookies, ip, acceptLanguage, body } = options;
  const headers = await makeHeaders({
    method,
    baseURL,
    cookies,
    ip,
    acceptLanguage,
    body,
  });

  const init = {
    ...options,
    ...baseOptions,
    body,
    headers,
    // fetch-retry parameters
    retries: 5,
    retryDelay: (attempt: number) => {
      // 250, 500, 1000, 2000...
      const power = Math.pow(2, attempt) * 250;
      // Add random jitter of up to the same amount, so smoothly spread
      return power + randomInteger({ max: power });
    },
    retryOn: [502, 503], // 502 Bad Gateway, 503 Service Unavailable
  };

  const fetchRetry = fetchRetryGenerator(fetch);
  return fetchRetry(url, init);
};
