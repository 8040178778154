import { Appboy } from 'src/types/braze';
import { generateTracker } from '../generateTracker';

export enum App {
  web = 'web',
  ios = 'ios',
  android = 'android',
}

export enum CommunicateCategory {
  intro = 'intro',
  game = 'game',
  mission = 'mission',
  chat = 'chat',
  all = 'all',
}

export const appFromString = (str: string): App => {
  switch (str) {
    case 'web':
      return App.web;
    case 'ios':
      return App.ios;
    case 'android':
      return App.android;
    default: {
      console.error('Invalid App value logged: ' + str);
      return App.web;
    }
  }
};

export const communicateCategoryFromString = (str: string): CommunicateCategory => {
  switch (str) {
    case 'intro':
      return CommunicateCategory.intro;
    case 'game':
      return CommunicateCategory.game;
    case 'mission':
      return CommunicateCategory.mission;
    case 'chat':
      return CommunicateCategory.chat;
    case 'all':
      return CommunicateCategory.all;
    default: {
      console.error('Invalid CommunicateCategory value logged: ' + str);
      return CommunicateCategory.intro;
    }
  }
};

/**
 * Fires when the chat screen loaded
 */
export const trackCommunicateSessionStart =
  (appboy?: Appboy) =>
  (
    communicateSessionId: string | null,
    app: App | null,
    conversationName: string | null,
    category: CommunicateCategory | null,
    languagePairId: number | null,
    userId: string | null,
    sourceLocale: string | null,
    targetLocale: string | null,
  ) =>
    generateTracker(appboy)('CommunicateSessionStart', () => ({
      communicate_session_id: communicateSessionId,
      app: app != null ? app.toString() : null,
      conversation_name: conversationName,
      category: category != null ? category.toString() : null,
      language_pair_id: languagePairId,
      user_id: userId,
      source_locale: sourceLocale,
      target_locale: targetLocale,
    }));

/**
 * Fires when the chat finished
 */
export const trackCommunicateSessionEnd =
  (appboy?: Appboy) =>
  (communicateSessionId: string | null, endState: string | null, numMessages: number | null) =>
    generateTracker(appboy)('CommunicateSessionEnd', () => ({
      communicate_session_id: communicateSessionId,
      end_state: endState,
      num_messages: numMessages,
    }));
