export const STRIPE_PRICES_LOOKUP_KEYS = {
  month: 'standard_monthly',
  year: 'standard_annual',
  lifetime: 'standard_lifetime',
};

// The list of zero-decimal currencies that we use in the app
export const STRIPE_ZERO_DECIMAL_CURRENCIES = ['CLP', 'JPY', 'KRW', 'VND'];

export const STRIPE_DEFAULT_CHECKOUT_LOCALE = 'en';
export const STRIPE_EQUAL_CHECKOUT_LOCALES = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'id',
  'it',
  'ja',
  'ko',
  'nl',
  'pl',
  'pt',
  'ru',
  'sv',
  'tr',
  'vi',
];
export const STRIPE_MAPPED_CHECKOUT_LOCALES: Record<string, string> = {
  ar: 'en',
  'es-mx': 'es',
  hi: 'en',
  no: 'no',
  'pt-br': 'pt',
  'zh-cn': 'zh',
  'zh-hant': 'zh',
};
