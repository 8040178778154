import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class LanguagePairsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/language_pairs' });
  }

  public async getSkillFrameworks(languagePairId: number): Promise<Types.SkillFrameworksResponse> {
    const response = await this.get<Schema.SkillFrameworksResponse>(
      `/${languagePairId}/skill_frameworks/`,
    );
    return toCamelCaseDeep(response);
  }

  public async getSkillLevels(
    languagePairId: number,
    skillFrameworkId: number,
  ): Promise<Types.SkillLevelsResponse> {
    const response = await this.get<Schema.SkillLevelsResponse>(
      `/${languagePairId}/skill_frameworks/${skillFrameworkId}/`,
    );
    return toCamelCaseDeep(response);
  }
}

export const LanguagePairsApi = new LanguagePairsApiClass();
