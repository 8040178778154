import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

type SourceLanguageResponse = Schema.SourceLanguageResponse[] & Record<string, unknown>;

export default class LanguageApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/language-pairs' });
  }

  public async getSourceLanguages(): Promise<Types.SourceLanguagesResponse> {
    const response = await this.get<Schema.SourceLanguagesResponse>(`/source-languages/`);
    return response.source_languages;
  }

  public async getSourceLanguage(
    sourceLanguage: string,
  ): Promise<Types.DetailedSourceLanguageResponse[]> {
    const response = await this.get<SourceLanguageResponse>(`/?source_language=${sourceLanguage}`);
    return response.map(language => toCamelCaseDeep(language));
  }
}

export const LanguageApi = new LanguageApiClass();
