import EventTrackerMapping from 'generated/event_tracker_mapping.json';
import { Appboy } from 'src/types/braze';
/*
  CommunicateTabOfficialClicked: 'communicate_tab_clicked',
  HomeTabOfficialClicked: 'home_tab_clicked',
  ImmerseTabOfficialClicked: 'immerse_tab_clicked',
  LearnTabOfficialClicked: 'learn_tab_clicked',
  SkillLevelSelected: 'user_skill_selected',
  AccountCreationCompleted: 'user_account_created',
  CommunicateSessionEnd: 'communicate_session_completed',
  CommunicateSessionStart: 'communicate_session_started',
  ContentMediaCompleted: 'immerse_media_completed',
  ContentMediaStarted: 'immerse_media_started',
  UserDailyGoalAchieved: 'user_dailygoal_achieved',
  LearningSessionCompleted: 'learn_session_completed',
  LearningSessionStarted: 'learn_session_started',
  UserStreakAchieved: 'user_streak_achieved',
  WordsLearnedGoalEdited: 'learn_wordgoal_selected',
  ScenarioCompleted: 'learn_scenario_completed',
  OrderCompleted: 'user_order_completed',
*/
export const BrazeEventMapping: { [key: string]: string } =
  EventTrackerMapping.event_tracker_mapping
    ?.flatMap(({ event_name, braze_event_name }) => ({
      key: event_name,
      value: braze_event_name,
    }))
    .reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {});

export const logCustomBrazeEvent = (
  appboy: Appboy | undefined,
  brazeEventName: string,
  eventProperties?: Record<string, unknown>,
) => {
  appboy?.logCustomEvent(brazeEventName, eventProperties);
  appboy?.requestImmediateDataFlush();
};

export const generateTracker =
  (appboy?: Appboy) =>
  <
    Builder extends (...args: Args) => EventProperties,
    // any is okay as we are extending it
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Args extends any[],
    EventProperties extends Record<string, unknown>,
  >(
    eventName: string,
    builder?: Builder,
  ): ((...params: Parameters<Builder>) => void) =>
  (...params) => {
    logCustomBrazeEvent(appboy, BrazeEventMapping[eventName], builder && builder(...params));
  };
