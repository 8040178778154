export const SOURCE_LANGUAGE_KEY = 'sourceLanguage';

export const getWebStorageEntry = (getStorage: () => Storage, name: string): string | null => {
  try {
    return getStorage().getItem(name) || null;
  } catch (e) {
    // if storage is missing just return
    return null;
  }
};

export const removeWebStorageEntry = (getStorage: () => Storage, name: string): void => {
  try {
    getStorage().removeItem(name);
  } catch (e) {
    // if storage is missing just return
  }
};

export const setWebStorageEntry = (
  getStorage: () => Storage,
  name: string,
  value: string,
): string | null => {
  try {
    getStorage().setItem(name, value);
    return value || /* istanbul ignore next: untested branch of code, please test */ null;
  } catch (e) {
    // setItem will throw exceptions in private mode on ios 5
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    return null;
  }
};

export const clearWebStorage = (getStorage: () => Storage): void => {
  try {
    getStorage().clear();
  } catch (e) {
    // if storage is missing just return
  }
};

export const forEachKeyInWebStorage = (
  getStorage: () => Storage,
  fn: (key: string) => void,
): void => {
  try {
    for (const key in getStorage()) {
      fn(key);
    }
  } catch (e) {
    // if storage is missing just return
  }
};

export const getLocalStorage = () => localStorage;
export const getLocalStorageEntry = (name: string) => getWebStorageEntry(getLocalStorage, name);
export const removeLocalStorageEntry = (name: string) =>
  removeWebStorageEntry(getLocalStorage, name);
export const setLocalStorageEntry = (name: string, value: string) =>
  setWebStorageEntry(getLocalStorage, name, value);
export const clearLocalStorage = () => clearWebStorage(getLocalStorage);
export const forEachKeyInLocalStorage = (fn: (key: string) => void) =>
  forEachKeyInWebStorage(getLocalStorage, fn);

export const getSessionStorage = () => sessionStorage;
export const getSessionStorageEntry = (name: string) => getWebStorageEntry(getSessionStorage, name);
export const removeSessionStorageEntry = (name: string) =>
  removeWebStorageEntry(getSessionStorage, name);
export const setSessionStorageEntry = (name: string, value: string) =>
  setWebStorageEntry(getSessionStorage, name, value);
export const clearSessionStorage = () => clearWebStorage(getSessionStorage);
export const forEachKeyInSessionStorage = (fn: (key: string) => void) =>
  forEachKeyInWebStorage(getSessionStorage, fn);
