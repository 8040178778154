import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Link from 'next/link';
import { ROUTES } from 'src/constants/routes.const';
import { Button } from '../Button';
import * as S from './styles';

const msgs = defineMessages({
  backHome: { id: 'ea_plans_back' },
  backCommunicate: { id: 'communicate_back' },
  back: { id: 'web_header_back' },
  backVideos: { id: 'core_loop_back_to_videos_CTA' },
});

export const BackHeader: FC<{
  href?: string;
  onClick?: () => void;
  hideText?: boolean;
  inverseColors?: boolean;
  beta?: boolean;
  betaText?: string;
}> = ({ href = ROUTES.dashboard.scenarios, onClick, hideText, inverseColors, beta, betaText }) => {
  const intl = useIntl();
  const logo = 'logo';

  const backText =
    {
      [ROUTES.dashboard.old]: intl.formatMessage(msgs.backHome),
      [ROUTES.dashboard.scenarios]: intl.formatMessage(msgs.backHome),
      [ROUTES.video.immerse]: intl.formatMessage(msgs.backVideos),
      [ROUTES.membot.select]: intl.formatMessage(msgs.backCommunicate),
      [ROUTES.ai.buddies]: 'Back to Buddies',
    }[href] || intl.formatMessage(msgs.back);

  const child = (
    <S.Content $inverseColors={inverseColors}>
      <S.ArrowIcon name="arrowLeft" useCurrentColor />
      {!hideText && <S.Text>{onClick ? intl.formatMessage(msgs.back) : backText}</S.Text>}
    </S.Content>
  );

  return (
    <S.Header $inverseColors={inverseColors} data-testid="header">
      <Link href={href} passHref legacyBehavior>
        <S.Redirect title={logo} aria-label={logo}>
          <S.LogoIcon name="memrise" useCurrentColor />
        </S.Redirect>
      </Link>
      {onClick ? (
        <S.BackButton>
          <Button buttonType="tertiary" onClick={onClick} buttonSize="extraSmall">
            {child}
          </Button>
        </S.BackButton>
      ) : (
        <Link href={href} passHref legacyBehavior>
          <S.Redirect aria-label={backText}>{child}</S.Redirect>
        </Link>
      )}
      {beta && (
        <S.BetaWrapper>
          <S.Beta
            data-testid="Beta-tag"
            href="https://memrise.zendesk.com/hc/en-us/articles/9314957339281"
            target="_blank"
            rel="noopener noreferrer"
          >
            {betaText && betaText + ` - `} Beta
          </S.Beta>
        </S.BetaWrapper>
      )}
    </S.Header>
  );
};
