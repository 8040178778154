import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Schema from './schema';

export default class ScenariosBetaApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getScenariosBetaStatus() {
    const response = await this.get<Schema.ScenariosBetaStatusResponse>(
      `/path_scenarios_beta/`,
      {},
    );
    return toCamelCaseDeep(response);
  }

  public async forceJoinScenariosBeta() {
    return this.post(`/path_scenarios_beta/force_join/`, {});
  }

  public async leaveScenariosBeta() {
    return this.post(`/path_scenarios_beta/leave/`, {});
  }
}

export const ScenariosBetaApi = new ScenariosBetaApiClass();
