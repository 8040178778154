import { toCamelCaseDeep } from 'src/utils/transform';
import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class UserScenarioClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getScenarioCounts(languagePairId: number): Promise<Types.ScenarioCountsResponse> {
    const response = await this.get<Schema.ScenarioCountsResponse>(
      `/language_pairs/${languagePairId}/scenario_counts/`,
    );
    return toCamelCaseDeep(response);
  }

  public async getUserScenarios(
    languagePairId: number,
    params?: Schema.UserScenariosRequest,
  ): Promise<Types.UserScenariosResponse> {
    const response = await this.get<Schema.UserScenariosResponse, Schema.UserScenariosRequest>(
      `/language_pairs/${languagePairId}/scenarios/`,
      params,
    );
    return toCamelCaseDeep(response);
  }

  public async getUserScenarioDetails(
    scenarioId: number,
  ): Promise<Types.UserScenarioLearnablesResponse> {
    const response = await this.get<Schema.UserScenarioLearnablesResponse>(
      `/scenarios/${scenarioId}/details/`,
    );
    return toCamelCaseDeep(response);
  }

  public async skipScenario(
    languagePairId: number,
    scenarioId: number,
    isHidden?: boolean,
  ): Promise<SuccessResponse> {
    return this.put(`/language_pairs/${languagePairId}/scenarios/${scenarioId}/`, {
      bodyObject: {
        is_hidden: isHidden,
      },
    });
  }
}

export const UserScenarioApi = new UserScenarioClass();
