import styled from 'styled-components';
import Icon from '../Icon';
import { SvgHelperWrapper } from '../Icon/iconHelper';

export const MarginAdjuster = styled.div`
  margin-top: -67px; /* height of swoosh */
  pointer-events: none;
`;

export const Swoosh = styled(SvgHelperWrapper)`
  position: relative;
  top: 0.5rem;
  width: 100%;
  pointer-events: none;
`;

export const FooterRoot = styled.div`
  background-color: ${props => props.theme.co.secondary20};
  pointer-events: all;

  * {
    color: ${props => props.theme.co.neutral90};
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${props => props.theme.sz.xl};
  padding: ${props => props.theme.sz.s4};
  width: 100%;

  @media (min-width: 40rem) {
    align-items: flex-end;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const AllLinks = styled.div`
  @media (min-width: 40rem) {
    flex: 1;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.theme.sz.s4} 0;
  width: 100%;
  flex-wrap: wrap;
`;

export const LinkColumn = styled.div`
  flex: 1 0 25%;
  padding-right: ${props => props.theme.sz.s2};

  &:last-child {
    padding-right: 0;
  }
`;

export const TermsLinkContainer = styled.div`
  font-size: ${props => props.theme.DEPRECATED_ty.smaller};
  list-style: none;
  margin-right: ${props => props.theme.sz.s4};
`;

export const LinkContainer = styled.div`
  font-size: ${props => props.theme.DEPRECATED_ty.small};
  list-style: none;
  margin-bottom: ${props => props.theme.sz.s2};
`;

export const ContainedLink = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.co.primary20};
  }
`;

export const MemriseIconContainer = styled.a`
  margin: 0 auto;
`;

export const MemriseIcon = styled(Icon)`
  svg {
    height: ${props => props.theme.sz.s16};
    width: ${props => props.theme.sz.s16};
  }
  svg path {
    fill: ${props => props.theme.co.primary99};
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 12rem;
  width: 100%;
`;

export const SocialIconContainer = styled.a`
  background: ${props => props.theme.co.error60};
  border-radius: 56% 48% 53% 56% / 70% 50% 58% 41%;
  padding: ${props => props.theme.sz.s1};
  position: relative;

  svg {
    height: 1.5rem;
    position: relative;
    width: 1.5rem;
  }

  /* Facebook */
  &:first-child svg {
    top: -5px;
  }

  /* Twitter */
  &:nth-child(2) svg {
    top: 1px;

    left: 4px;
  }

  /* YouTube */
  &:nth-child(3) svg {
    top: 2px;

    left: 4px;
  }

  /* Instagram */
  &:last-child svg {
    top: 4px;

    left: -2px;
  }
`;

export const AllIcons = styled.div`
  @media (min-width: 40rem) {
    flex: 0 0 ${props => props.theme.sz.s48};
    margin-right: ${props => props.theme.sz.s12};
  }
`;
