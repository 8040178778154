import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

const formatDialogue = (dialogue: Types.DialogueLine[]) =>
  dialogue.map(dialogueLine => ({
    is_bot: dialogueLine.isBot,
    is_prompt: dialogueLine.isPrompt,
    text_en: dialogueLine.textEn,
    text_src: dialogueLine.textSrc,
    text_tgt: dialogueLine.textTgt,
    text_tgt_roman: dialogueLine.textTgtRoman,
  }));

class MembotApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/membot' });
  }

  public async getMissions(languagePairId: number): Promise<Types.MissionsResponse> {
    const response = await this.get<Schema.MissionsResponse>(`/${languagePairId}/missions/`);
    return toCamelCaseDeep(response);
  }

  public async getMission(
    languagePairId: number,
    locationSlug: string,
  ): Promise<Types.MissionResponse> {
    const response = await this.get<Schema.MissionResponse>(
      `/${languagePairId}/missions/${locationSlug}/`,
    );
    return toCamelCaseDeep(response);
  }

  public async getHint(
    languagePairId: number,
    locationSlug: string,
    dialogue: Types.DialogueLine[],
  ): Promise<Types.HintResponse> {
    const response = await this.post<Schema.HintResponse, Schema.HintRequest>(
      `/${languagePairId}/missions/${locationSlug}/hint/`,
      {
        bodyObject: {
          number_of_hints: 2,
          dialogue: formatDialogue(dialogue),
        },
      },
    );
    return toCamelCaseDeep(response);
  }

  public async getDialogue(
    languagePairId: number,
    locationSlug: string,
    dialogue: Types.DialogueLine[],
  ): Promise<Types.DialogueResponse> {
    const response = await this.post<Schema.DialogueResponse, Schema.DialogueRequest>(
      `/${languagePairId}/missions/${locationSlug}/dialogue/`,
      {
        bodyObject: {
          dialogue: formatDialogue(dialogue),
        },
      },
    );
    return toCamelCaseDeep(response);
  }

  public async checkGoalReached(
    languagePairId: number,
    locationSlug: string,
    dialogue: Types.DialogueLine[],
  ): Promise<Types.GoalReachedResponse> {
    const response = await this.post<Schema.GoalReachedResponse, Schema.DialogueRequest>(
      `/${languagePairId}/missions/${locationSlug}/goal/`,
      {
        bodyObject: {
          dialogue: formatDialogue(dialogue),
        },
      },
    );
    return toCamelCaseDeep(response);
  }

  public async fixMessage(
    languagePairId: number,
    message: string,
  ): Promise<Types.FixMessageResponse> {
    const response = await this.post<Schema.FixMessageResponse, Schema.FixMessageRequest>(
      `/${languagePairId}/fix_message/`,
      {
        bodyObject: {
          message,
        },
      },
    );
    return toCamelCaseDeep(response);
  }

  public async speechToTextToken(languagePairId: number): Promise<Types.SpeechToTextTokenResponse> {
    const response = await this.post<
      Schema.SpeechToTextTokenResponse,
      Schema.SpeechToTextTokenRequest
    >(`/${languagePairId}/speech_to_text/token/`, {});
    return toCamelCaseDeep(response);
  }

  public async translate(
    sourceLanguageLocale: string,
    targetLanguageLocale: string,
    text: string,
  ): Promise<Types.TranslateResponse> {
    const response = await this.post<Schema.TranslateResponse, Schema.TranslateRequest>(
      `/translate/`,
      {
        bodyObject: {
          source_language_locale: sourceLanguageLocale,
          target_language_locale: targetLanguageLocale,
          text,
        },
      },
    );
    return toCamelCaseDeep(response);
  }
}

export const MembotApi = new MembotApiClass();
