import { toCamelCaseDeep } from 'src/utils/transform';
import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class StreakApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getStreak(languagePairId: number): Promise<Types.StreakResponse> {
    const response = await this.get<Schema.StreakResponse>(
      `/language_pairs/${languagePairId}/streak/`,
    );
    return toCamelCaseDeep(response);
  }

  public async postStreak(
    languagePairId: number,
    params: Schema.StreakRequest,
  ): Promise<SuccessResponse> {
    return this.post(`/language_pairs/${languagePairId}/streak/`, {
      bodyObject: params,
    });
  }
}

export const StreakApi = new StreakApiClass();
