import {
  STRIPE_DEFAULT_CHECKOUT_LOCALE,
  STRIPE_EQUAL_CHECKOUT_LOCALES,
  STRIPE_MAPPED_CHECKOUT_LOCALES,
} from 'src/constants/stripe.const';
import MemriseApi from '../MemriseApi';
import { StripeCheckoutSessionRequest, StripeCheckoutSessionResponse } from './schemas';
import { StripeCheckoutSessionParams } from './types';

export default class PaymentsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/web/payments' });
  }

  async stripeCreateCheckoutSession({
    price,
    successUrl,
    locale,
  }: StripeCheckoutSessionParams): Promise<string> {
    const [, priceId] = price.key.split(':');
    const [couponId, promoCodeId] = price.discount?.key.split(':') || [];
    const stripeLocale = STRIPE_EQUAL_CHECKOUT_LOCALES.includes(locale)
      ? locale
      : STRIPE_MAPPED_CHECKOUT_LOCALES[locale] || STRIPE_DEFAULT_CHECKOUT_LOCALE;

    const response = await this.post<StripeCheckoutSessionResponse, StripeCheckoutSessionRequest>(
      '/stripe/checkout_session',
      {
        bodyObject: {
          mode: price.paymentPeriod === 'lifetime' ? 'payment' : 'subscription',
          price: priceId,
          ...(price.discount
            ? {
                discounts: [
                  promoCodeId
                    ? {
                        promotion_code: promoCodeId,
                      }
                    : {
                        coupon: couponId,
                      },
                ],
              }
            : {}),
          success_url: successUrl,
          locale: stripeLocale,
          currency: price.currency,
          allow_promotion_codes: true,
          billing_address_collection: 'required',
        },
      },
    );

    return response.session_url;
  }
}

export const PaymentsApi = new PaymentsApiClass();
