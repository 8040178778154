import { toCamelCaseDeep } from 'src/utils/transform';
import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class BusinessModelApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getBusinessModelToken(
    languagePairId: number,
    params: Schema.BusinessModelRequest,
  ): Promise<Types.BusinessModelResponse> {
    const response = await this.get<Schema.BusinessModelResponse, Schema.BusinessModelRequest>(
      `/language_pairs/${languagePairId}/tokens/`,
      params,
    );
    return toCamelCaseDeep(response);
  }

  public async removeBusinessModelToken(
    languagePairId: number,
    params: Schema.BusinessModelRequest,
  ): Promise<SuccessResponse> {
    return this.delete(`/language_pairs/${languagePairId}/tokens/`, {
      queryParams: params,
    });
  }

  public async postBusinessModelToken(
    languagePairId: number,
    params: Schema.BusinessModelRequest,
  ): Promise<SuccessResponse> {
    return this.post(`/language_pairs/${languagePairId}/tokens/`, {
      queryParams: params,
    });
  }
}

export const BusinessModelApi = new BusinessModelApiClass();
