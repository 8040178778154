import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class LevelsAndStagesApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/' });
  }

  public async getLevels(): Promise<Types.ProgressLevel[]> {
    const response = await this.get<Schema.LevelsResponse>(`/levels/`);
    return toCamelCaseDeep(response).levels;
  }

  public async getStages(): Promise<Types.ProgressStage[]> {
    const response = await this.get<Schema.StagesResponse>(`/stages/`);
    return toCamelCaseDeep(response).stages;
  }
}

export const LevelsAndStagesApi = new LevelsAndStagesApiClass();
