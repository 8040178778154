import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Schema from './schema';
import * as Types from './types';

export default class LanguageCodePairsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/language_code_pairs' });
  }

  public async searchLearnables(
    sourceLanguageCode: string,
    targetLanguageCode: string,
    params: Schema.LanguagePairLearnablesSearchRequest,
  ): Promise<Types.LanguagePairLearnablesSearchResponse> {
    const endpoint = `/${sourceLanguageCode}/${targetLanguageCode}/learnables/search/`;

    const response = await this.get<
      Schema.LanguagePairLearnablesSearchResponse,
      Schema.LanguagePairLearnablesSearchRequest
    >(endpoint, params);

    return toCamelCaseDeep(response);
  }
}

export const LanguageCodePairsApi = new LanguageCodePairsApiClass();
