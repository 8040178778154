import { stringifyUrl } from 'src/utils/stringifyUrl';
import { PremiumConfig } from './schema';

export default class InternalApiClass {
  private async api<T>(url: string): Promise<T> {
    const API_PREFIX = '/api';

    return fetch(`${API_PREFIX}${url}`).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    });
  }

  public async getPremiumConfig({
    userCohorts,
    ...restParams
  }: {
    country: string;
    userCohorts: string[];
    specialPrice?: string;
    specialOffer?: string;
    specialOfferPriority?: string;
  }): Promise<PremiumConfig> {
    return await this.api<PremiumConfig>(
      stringifyUrl('/premium-config', {
        ...restParams,
        userCohorts: userCohorts.join(','),
      }),
    );
  }
}

export const InternalApi = new InternalApiClass();
