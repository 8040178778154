import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class PathApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/paths' });
  }

  public async enroll(languagePairId: number): Promise<Types.EnrollResponse> {
    const response = await this.post<Schema.EnrollResponse>(`/${languagePairId}/enroll/`, {});
    return toCamelCaseDeep(response);
  }

  public async getPathsOverview(
    params: Schema.PathOverviewRequest,
  ): Promise<Types.PathOverviewResponse> {
    const response = await this.get<Schema.PathOverviewResponse, Schema.PathOverviewRequest>(
      '/overview/',
      params,
    );
    return toCamelCaseDeep(response);
  }
}

export const PathApi = new PathApiClass();
