import MemriseApi from '../MemriseApi';
import { convertGetScenarioResponse } from './conversion';
import * as Schema from './schema';
import * as Types from './types';

class ScenariosApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/scenarios' });
  }

  public async getScenario(scenarioId: number | string): Promise<Types.ScenariosItem> {
    const endpoint = `/${scenarioId}/`;
    const response = await this.get<Schema.ScenariosItem>(endpoint);
    return convertGetScenarioResponse(response);
  }
}

export const ScenariosApi = new ScenariosApiClass();
