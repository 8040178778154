import accent from './icons/accent.svg';
import ad from './icons/ad.svg';
import aeroplane from './icons/aeroplane.svg';
import alertFilled from './icons/alert_filled.svg';
import alertOutline from './icons/alert_outline.svg';
import appleButtonIcon from './icons/apple-button-icon.svg';
import arrowDown from './icons/arrow-down.svg';
import arrowLeft from './icons/arrow-left.svg';
import arrowRight from './icons/arrow-right.svg';
import audioWave from './icons/audio-wave.svg';
import bell from './icons/bell.svg';
import blob1 from './icons/blob1.svg';
import book from './icons/book.svg';
import bullseye from './icons/bullseye.svg';
import bullseyeFull from './icons/bullseye-full.svg';
import bullseyeThick from './icons/bullseye-thick.svg';
import burger from './icons/burger.svg';
import burgerThin from './icons/burger-thin.svg';
import caretDown from './icons/caret-down.svg';
import celebration from './icons/celebration.svg';
import checkmark from './icons/checkmark.svg';
import checkmarkNew from './icons/checkmark-new.svg';
import chevronDown from './icons/chevron-down.svg';
import chevronLeft from './icons/chevron-left.svg';
import chevronRight from './icons/chevron-right.svg';
import chevronUp from './icons/chevron-up.svg';
import circle from './icons/circle.svg';
import circleFilled from './icons/circle-filled.svg';
import close from './icons/close.svg';
import communicateFilled from './icons/communicate_filled.svg';
import communicateOutline from './icons/communicate_outline.svg';
import communityOutline from './icons/community_outlline.svg';
import connectionError from './icons/connection-error.svg';
import cross from './icons/cross.svg';
import crossNew from './icons/cross-new.svg';
import devTools from './icons/dev-tools.svg';
import difficultWords from './icons/difficult-words.svg';
import difficultWordsFilled from './icons/difficult-words_filled.svg';
import difficultWordsOutline from './icons/difficult-words_outline.svg';
import displayLeft from './icons/display-left.svg';
import displayRight from './icons/display-right.svg';
import document from './icons/document.svg';
import dropdownGoal from './icons/dropdownGoal.svg';
import dropdownRecently from './icons/dropdownRecently.svg';
import dropdownTag from './icons/dropdownTag.svg';
import dropdownTeaching from './icons/dropdownTeaching.svg';
import edit from './icons/edit.svg';
import ellipsis from './icons/ellipsis.svg';
import ellipsisNew from './icons/ellipsis-new.svg';
import emptyFlag from './icons/empty-flag.svg';
import expandArrow from './icons/expand-arrow.svg';
import explore_home from './icons/explore_home.svg';
import explore_learn from './icons/explore_learn.svg';
import facebook from './icons/facebook.svg';
import facebookButtonIcon from './icons/facebook-button-icon.svg';
import facebookShareIcon from './icons/facebook-share-icon.svg';
import filter from './icons/filter.svg';
import fire from './icons/fire.svg';
import fireDisabled from './icons/fire_disabled.svg';
import fireworks from './icons/fireworks.svg';
import flag from './icons/flag.svg';
import glassesFilled from './icons/glasses_filled.svg';
import glassesOutline from './icons/glasses_outline.svg';
import goal from './icons/goal.svg';
import googleButtonIcon from './icons/google-button-icon-1.svg';
import grid from './icons/grid.svg';
import headphones from './icons/headphones.svg';
import hintDefault from './icons/hint-default.svg';
import hintHovered from './icons/hint-hovered.svg';
import hintSelected from './icons/hint-selected.svg';
import home from './icons/home.svg';
import homeFilled from './icons/home_filled.svg';
import homeOutline from './icons/home_outline.svg';
import ideaOutline from './icons/idea_outline.svg';
import ignoreWord from './icons/ignore-word.svg';
import info from './icons/info.svg';
import infoOutline from './icons/info-outline.svg';
import instagram from './icons/instagram.svg';
import keyboard from './icons/keyboard.svg';
import languages from './icons/languages.svg';
import learn from './icons/learn.svg';
import learnFilled from './icons/learn_filled.svg';
import learnOutline from './icons/learn_outline.svg';
import learnWithLocals from './icons/learn-with-locals.svg';
import lightningBoltInCircle from './icons/lightning_bolt_circle.svg';
import likeFilled from './icons/like_filled.svg';
import likeOutline from './icons/like_outline.svg';
import link from './icons/link.svg';
import listening from './icons/listening.svg';
import loader from './icons/loader.svg';
import locked from './icons/locked.svg';
import membotAi from './icons/membot-ai.svg';
import memrise from './icons/memrise.svg';
import memriseBlob from './icons/memrise-blob.svg';
import memriseSmallBlack from './icons/memrise-small-black.svg';
import microphoneFilled from './icons/microphone_filled.svg';
import more from './icons/more.svg';
import noAdsWhite from './icons/no-ads_white.svg';
import noAdsOutline from './icons/no-ads_outline.svg';
import noStreak from './icons/no-streak.svg';
import padlock from './icons/padlock.svg';
import padlockGradient from './icons/padlock-gradient.svg';
import pause from './icons/pause.svg';
import play from './icons/play.svg';
import play_filled from './icons/play_filled.svg';
import playVideo from './icons/play_video.svg';
import plus from './icons/plus.svg';
import practiceFilled from './icons/practice_filled.svg';
import practiceOutline from './icons/practice_outline.svg';
import premium from './icons/premium.svg';
import premiumStar from './icons/premium-star.svg';
import premiumStarNoFill from './icons/premium-star-no-fill.svg';
import premiumStarPurple from './icons/premium-star-purple.svg';
import profile from './icons/profile.svg';
import profileIcon from './icons/profile-icon.svg';
import question from './icons/question.svg';
import relationships from './icons/relationships.svg';
import replay from './icons/replay.svg';
import replayThin from './icons/replay_thin.svg';
import review from './icons/review.svg';
import reviewNew from './icons/review-new.svg';
import rocket from './icons/rocket.svg';
import search from './icons/search.svg';
import searchThin from './icons/search_thin.svg';
import sendFilled from './icons/send_filled.svg';
import sentences from './icons/sentences.svg';
import settingsFilled from './icons/settings_filled.svg';
import settingsOutline from './icons/settings_outline.svg';
import social from './icons/social.svg';
import sort from './icons/sort.svg';
import sortZa from './icons/sort-za.svg';
import sortAz from './icons/sort-az.svg';
import soundFull from './icons/sound_full.svg';
import soundHalf from './icons/sound_half.svg';
import soundMuted from './icons/sound_muted.svg';
import speakerActive from './icons/speaker-active.svg';
import speakerDefault from './icons/speaker-default.svg';
import speedLearnable from './icons/speed-learnable.svg';
import speedReview from './icons/speed-review.svg';
import speedReviewNew from './icons/speed-review-new-filled.svg';
import speedReviewNewOutlined from './icons/speed-review-new-outlined.svg';
import star from './icons/star.svg';
import stars from './icons/stars.svg';
import starsCompleted from './icons/starsCompleted.svg';
import streak from './icons/streak.svg';
import starsSet from './icons/stars-set.svg';
import subtitles from './icons/subtitles.svg';
import tick from './icons/tick.svg';
import tickFilled from './icons/tick_filled.svg';
import tickOutline from './icons/tick_outline.svg';
import topicWorkOutline from './icons/topic-work_outline.svg';
import topicOutline from './icons/topics_outline.svg';
import translate from './icons/translate.svg';
import twitter from './icons/twitter.svg';
import twitterShareIcon from './icons/twitter-share-icon.svg';
import unlockWhite from './icons/unlock_white.svg';
import unlockOutline from './icons/unlock_outline.svg';
import unlocked from './icons/unlocked.svg';
import videoLibraryFilled from './icons/video-library_filled.svg';
import videoLibraryOutline from './icons/video-library_outline.svg';
import videoLibraryWhite from './icons/video-library_white.svg';
import wand from './icons/wand.svg';
import warning from './icons/warning.svg';

export default {
  accent,
  ad,
  aeroplane,
  alertFilled,
  alertOutline,
  appleButtonIcon,
  arrowDown,
  arrowLeft,
  arrowRight,
  audioWave,
  bell,
  blob1,
  book,
  bullseye,
  bullseyeFull,
  bullseyeThick,
  burger,
  burgerThin,
  caretDown,
  celebration,
  checkmark,
  checkmarkNew,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  circle,
  circleFilled,
  close,
  communicateFilled,
  communicateOutline,
  communityOutline,
  connectionError,
  cross,
  crossNew,
  devTools,
  difficultWords,
  difficultWordsFilled,
  difficultWordsOutline,
  displayLeft,
  displayRight,
  document,
  dropdownGoal,
  dropdownRecently,
  dropdownTag,
  dropdownTeaching,
  edit,
  ellipsis,
  ellipsisNew,
  emptyFlag,
  expandArrow,
  explore_home,
  explore_learn,
  facebook,
  facebookButtonIcon,
  facebookShareIcon,
  filter,
  fire,
  fireDisabled,
  fireworks,
  flag,
  glassesFilled,
  glassesOutline,
  goal,
  googleButtonIcon,
  grid,
  headphones,
  hintDefault,
  hintHovered,
  hintSelected,
  home,
  homeFilled,
  homeOutline,
  ideaOutline,
  ignoreWord,
  info,
  infoOutline,
  instagram,
  keyboard,
  languages,
  learn,
  learnFilled,
  learnOutline,
  learnWithLocals,
  lightningBoltInCircle,
  likeFilled,
  likeOutline,
  link,
  listening,
  loader,
  locked,
  membotAi,
  memrise,
  memriseBlob,
  memriseSmallBlack,
  microphoneFilled,
  more,
  noAdsWhite,
  noAdsOutline,
  noStreak,
  padlock,
  padlockGradient,
  pause,
  play,
  play_filled,
  playVideo,
  plus,
  practiceFilled,
  practiceOutline,
  premium,
  premiumStar,
  premiumStarNoFill,
  premiumStarPurple,
  profile,
  profileIcon,
  question,
  relationships,
  replay,
  replayThin,
  review,
  reviewNew,
  rocket,
  search,
  searchThin,
  sendFilled,
  sentences,
  settingsFilled,
  settingsOutline,
  social,
  sort,
  sortZa,
  sortAz,
  soundFull,
  soundHalf,
  soundMuted,
  speakerActive,
  speakerDefault,
  speedLearnable,
  speedReview,
  speedReviewNew,
  speedReviewNewOutlined,
  star,
  stars,
  starsCompleted,
  streak,
  starsSet,
  subtitles,
  tick,
  tickFilled,
  tickOutline,
  topicWorkOutline,
  topicOutline,
  translate,
  twitter,
  twitterShareIcon,
  unlockWhite,
  unlockOutline,
  unlocked,
  videoLibraryFilled,
  videoLibraryOutline,
  videoLibraryWhite,
  wand,
  warning,
};
