/* eslint-disable no-console */
import { ComponentProps } from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';

export const intlErrorHandler: NonNullable<
  ComponentProps<typeof IntlProvider>['onError']
> = err => {
  if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
    // Displays MISSING_TRANSLATION errors as warnings in a collapsed group
    console.groupCollapsed('Missing translation');
    console.warn(err.message);
    console.groupEnd();
    return;
  }
  throw err;
};

const MESSAGES_LOCALE_TO_INTL_LOCALE_MAP: Record<string, string> = {
  // Prevent use of Eastern Arabic numerals, instead use Western Arabic numerals
  // as for every other supported locale.
  ar: 'ar-u-nu-latn',
};

export const intlGetProviderLocale = (locale: string): string => {
  return MESSAGES_LOCALE_TO_INTL_LOCALE_MAP[locale] || locale;
};

export const intlGetMessagesLocale = (locale: string): string => {
  return (Object.entries(MESSAGES_LOCALE_TO_INTL_LOCALE_MAP).find(
    ([_, value]) => value === locale,
  ) || [locale])[0];
};
