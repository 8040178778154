import { IntlShape } from 'react-intl';
import store from 'store';
import { v4 as uuidv4 } from 'uuid';
import { getAnalyticsLanguageInfo } from 'src/utils/analytics/getAnalyticsLanguageInfo';
import { getAnalyticsUserInfo, User } from 'src/utils/analytics/getAnalyticsUserInfo';
import { Appboy } from 'src/types/braze';
import { PremiumPrice } from 'src/api/internal/schema';
import { logCustomBrazeEvent } from 'src/analytics/generateTracker';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export enum PaymentProvider {
  stripe = 'stripe',
  paypal = 'paypal',
  chargebee = 'chargebee',
  unknown = 'unknown',
}

export const trackAndSaveCheckoutStarted = (
  appboy: Appboy | undefined,
  user: User,
  intl: IntlShape,
  price: PremiumPrice,
) => {
  const analyticsLanguageInfo = getAnalyticsLanguageInfo(intl);
  const analyticsItemCategories = {
    ...(analyticsLanguageInfo.source_language && {
      item_category: analyticsLanguageInfo.source_language,
    }),
    ...(analyticsLanguageInfo.target_language && {
      item_category2: analyticsLanguageInfo.target_language,
    }),
  };

  const ecommerce = {
    currency: price.currency,
    value: price.finalPrice,
    items: [
      {
        item_id: price.key,
        item_name: price.paymentPeriod === 'lifetime' ? 'LIFETIME' : `1-${price.paymentPeriod}`,
        ...analyticsItemCategories,
        price: price.finalPrice,
        discount: price.discount?.priceDifference || 0,
        quantity: 1,
      },
    ],
  };

  // Send the event without adding it to the analytics-schema to first verify its suitability for reporting
  logCustomBrazeEvent(appboy, 'checkout_started');

  window.dataLayer.push({
    event: 'begin_checkout',
    page_screen_type: 'Subscriptions',
    page_screen_name: 'Checkout',
    ...analyticsLanguageInfo,
    ...getAnalyticsUserInfo(user),
    ecommerce,
  });

  store.set(
    CHECKOUT_SESSION_TRACKING_STORE,
    JSON.stringify({
      userId: user.id,
      orderId: uuidv4(),
      paymentProvider: PaymentProvider.stripe,
      selectedPlan: price,
      ecommerce,
    }),
  );
};

export const CHECKOUT_SESSION_TRACKING_STORE = 'checkout-session-tracking';

export const trackOrderCompletedIfSaved = (user: User, intl: IntlShape) => {
  const savedCheckoutSession: string | null = store.get(CHECKOUT_SESSION_TRACKING_STORE);

  if (savedCheckoutSession) {
    const { orderId, paymentProvider, selectedPlan, ecommerce } = JSON.parse(savedCheckoutSession);
    const coupon = (
      selectedPlan.discount?.userFacingKey || selectedPlan.discount?.key
    )?.toLocaleLowerCase();

    window.dataLayer.push({
      event: 'purchase',
      page_screen_type: 'Subscriptions',
      page_screen_name: 'Purchase',
      ...getAnalyticsLanguageInfo(intl),
      ...getAnalyticsUserInfo(user),
      ecommerce: {
        ...ecommerce,
        transaction_id: orderId,
        payment_type: paymentProvider,
        ...(coupon && { coupon }),
      },
    });

    store.remove(CHECKOUT_SESSION_TRACKING_STORE);
    return;
  }
};
