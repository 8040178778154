import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class BuddyApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/language_pairs' });
  }

  public async sendMessage(
    languagePairId: number,
    buddyId: string,
    dialog: Types.DialogLines,
    specialRequest?: string | null,
    isNewChat?: boolean,
  ): Promise<Schema.BotResponse> {
    // @ts-ignore: not satisfy the constraint Record<string, unknown>
    const response = await this.post<Schema.BotResponse, Types.DialogLines>(
      `/${languagePairId}/buddies/${buddyId}/conversations/`,
      {
        bodyObject: {
          full_conversation: dialog,
          ...(specialRequest && { special_request: specialRequest }),
          ...(isNewChat && { is_new_chat: isNewChat }),
        },
      },
    );

    return toCamelCaseDeep(response);
  }

  public async getAll(languagePairId: number): Promise<Types.BuddiesResponse> {
    const response = await this.get<Schema.BuddiesResponse>(`/${languagePairId}/buddies/`);
    return toCamelCaseDeep(response);
  }
}

export const BuddyApi = new BuddyApiClass();
